.Scatter {
  position: relative;
  width: 100%;
  max-width: 950px;
  min-height: 600px;
  margin: 65px 0 400px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 300px;
  /* debugging */
  /* border: 1px solid aquamarine;
  box-sizing: border-box; */
}

/* .Card {
  z-index: 4;
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: aquamarine;
  border: 2px solid red;
} */
.Card {
  z-index: 4;
  position: absolute;
  /* height: 200px; */
  width: 350px;
  background-color: rgb(233, 224, 207);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Image {
  height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.Backdrop.BD_Active {
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Card.C_Active {
  transform: unset !important;
  width: 500px;
}

.TempImage {
  height: 100%;
  width: 100%;
  position: relative;
}

.ImagesDesc {
  display: none;
  color: #13001c;
}

.ID_Active {
  display: block;
}

.ImageFilter {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  background-image: url('../../assets/images/gallery/oldphototexture.png');
  opacity: 0.3;
}

@media all and (max-width: 1280px) {
  /* .Backdrop {
    display: blox;
  } */
  .Scatter {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 50px;
    margin: 65px 0 65px 0;
  }
  .Card {
    transform: unset !important;
    position: relative;
  }
  .GridSquare {
    min-height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ImagesDesc {
    display: block;
  }
}
