.Friends {
  /* width: 100vw; */
  margin: 30px;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Entries {
  width: 100%;
  max-width: 950px;
}

.Entry {
  background-color: #121112;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.Entry img {
  width: 100%;
  max-width: 300px;
}

.Links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

/* Desktop */
@media only screen and (min-width: 950px) {
  .Friends {
    margin: 30px 0;
  }
}
