.book_wrapper {
  position: relative;
  width: 100%;
  background-image: linear-gradient(#22202400, #000000);
}
.book_infomation_wrapper {
  padding: 3em 0;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
}
.book_infomation_container {
  height: 100%;
  /* width: 50%; */
  display: flex;
  flex-direction: column;
}
.book_background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/images/palace_backdrop.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -9999;
}

.book_infomation_cover_wrapper img {
  width: 250px;
}
.book_infomation_cover_wrapper {
  flex-grow: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book_data_wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.book_data_container > ul {
  list-style: none;
  font-weight: 300;
  margin: 1rem 0rem;
  letter-spacing: 0.1rem;
}

.book_data_container > ul > li {
  margin: 0.5rem 0rem;
  min-height: 2rem;
}
.basket_buton {
  min-width: 280px;
  height: 75px;
}
.book_data_container button {
  color: white;
  font-family: roboto;
  font-weight: 300;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  text-align: center;
  border: 1px solid transparent;
  margin-top: 1rem;
  background-color: #d23263;
}
.book_data_container {
  /* margin-left: 3rem; */
}

.book_data_container h1 {
  font-size: 5rem;
}
.book_data_container h2 {
  font-size: 3rem;
  font-weight: 300;
}
.book_data_container h3 {
  font-size: 1.5rem;
  font-weight: 300;
}

.book_review_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.book_review_container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10%;
  min-height: 250px;
  color: white;
  font-family: roboto;
}
.review_content_toggle {
  all: unset;
  padding: 0.5rem 0;
  font-weight: 900;
}
.shopify-buy__btn-wrapper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.rover_bg_color button {
  background-color: #d23263;
}
.kalkin_bg_color button {
  background-color: #2274a5;
}
.aditi_bg_color button {
  background-color: #ff8400;
}

.book_infomation_cover_container {
  min-width: 250px;
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#buyButtonTarget {
  min-width: 250px;
  min-height: 78px;
}
