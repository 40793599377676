.SideLinkWrapper {
  position: fixed;
  left: 0;
  bottom: calc(50% - 75px);
  z-index: 4;
}

.SideLink {
  height: 150px;
  width: 40px;
  background-color: #d23263;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
}
.SideLink > p {
  transform: rotate(-90deg);
  padding: 5px;
  font-size: 1.2rem;
  letter-spacing: 0.15em;
  /* font-family: caveat; */
  font-weight: 300;
  white-space: nowrap;
}
/* mobile */
@media only screen and (max-width: 700px) {
  .SideLinkWrapper {
    right: 0;
    left: unset;
  }
  .SideLink {
    height: 125px;
    width: 30px;
    border-radius: 5px 0 0 5px;
  }
  .SideLink > p {
    font-size: 0.8rem;
  }
}
