nav {
  /* padding: 0 15px; */
  min-height: 100px;
}
a {
  color: #fff;
  text-decoration: none;
}
.header {
  position: relative;
  z-index: 5;
}
.menu,
.submenu {
  list-style-type: none;
}
.toggle {
  order: 2;
}
.logo {
  order: 1;
}
.item {
  order: 3;
  cursor: pointer;
}

.logo a img {
  height: 100px;
  padding: 7.5px 10px 7.5px 15px;
  color: white;
}
.item {
  padding: 10px 15px;
  background-clip: padding-box;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 300;
  font-size: 1.5em;
  justify-content: center;
}
.menu li a {
  display: block;
  padding: 15px 0px;
  letter-spacing: 0.2rem;
  color: #e6ddce;
}
.menu li.subitem a {
  padding: 15px;
}
.toggle {
  order: 3;
  font-size: 20px;
  width: 100%;
  display: block;
  text-align: center;
}

.item {
  width: 100%;
  text-align: center;
  display: none;
}
/* .item a:hover {
  background-clip: padding-box;
  background-color: #e2e2e2;
} */
.active .item {
  display: block;
}

/* Submenu up from mobile screens */
.submenu {
  display: none;
  background-color: #1a181b;
  border: 1px solid black;
  position: relative;
  z-index: 1;
}

.active {
  position: fixed;
  background-color: #1a181bf7;
  height: 100%;
  width: 100%;
  top: 0;
}

.submenu-active .submenu {
  display: block;
  background-color: #1a181bf3;
}
.has-submenu {
  padding: 10px 15px;
}
.has-submenu i {
  font-size: 12px;
}
/* .has-submenu a:hover {
  background-color: #e2e2e2;
} */
/* .has-submenu > a::after {
  font-family: 'Font Awesome 5 Free';
  font-size: 12px;
  line-height: 16px;
  font-weight: 900;
  content: '\f078';
  color: white;
  padding-left: 5px;
} */
.subitem a {
  padding: 15px 15px;
}

.logOut {
  all: unset;
  padding: 15px 15px;
  color: #303a52;
}

.item.login a {
  all: unset;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #303a52;
  -webkit-box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}

/* Tablet menu */
@media all and (min-width: 700px) {
  .item,
  .logo {
    margin: 0px 30px;
    box-sizing: border-box;
  }
  .menu {
    justify-content: center;
  }
}

/* Desktop menu */
@media all and (min-width: 960px) {
  .logo,
  .item,
  .toggle {
    order: unset;
  }
  .menu {
    flex-wrap: nowrap;
    background: none;
  }
  .logo a img {
    height: 150px;
    padding: 7.5px 10px 7.5px 15px;
    color: white;
  }
  .item {
    /* order: 1; */
    position: relative;
    display: block;
    width: auto;
  }
  .submenu-active .submenu {
    display: block;
    position: absolute;
    left: 0;
    min-width: 100px;
    z-index: 9999;
  }

  .submenu {
    background-color: #1a181b;
    border: 1px solid black;
  }
  .submenu li a {
    padding: 15px 15px !important;
  }
  .toggle {
    display: none;
  }
  .submenu-active {
    border-radius: 0;
  }
  /* .item a:hover {
    background-clip: padding-box;
    background-color: transparent;
  } */
}
