@media all and (min-width: 700px) {
  .book_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .book_infomation_wrapper {
    width: 950px;
  }
  .book_infomation_container {
    width: 100%;
    flex-direction: row;
    gap: 5rem;
  }
  .book_data_wrapper {
    text-align: left;
    justify-content: start;
  }

  .shopify-buy__btn-wrapper {
    justify-content: flex-start !important;
  }
  .book_review_container {
    width: 950px;
  }
  .book_infomation_cover_wrapper {
    width: 50%;
    justify-content: flex-end;
  }
  .book_data_wrapper {
    width: 50%;
  }
}
