.Stars ul {
  list-style: none;
}
.Stars li {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Stars img {
  height: 1rem;
  width: 1rem;
  color: white;
}
