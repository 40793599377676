/* Desktop style */

@media all and (min-width: 700px) {
  .aboutKalkinSection {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    margin: 0px 20%;
    max-width: 950px;
    max-height: 765px;
  }
  .aboutKalkinSection .book_intro > h1 {
    line-height: 3.5rem !important;
  }
  .aboutKalkinSection h1.title {
    position: relative;
    z-index: -1;
    font-family: Remington;
    /* color: #d23263; */
    font-size: 5rem;
  }
  .aboutKalkinSection p.subtext {
    margin-top: 10px;
    position: relative;
    z-index: -1;
    font-family: Remington;
    color: #ffffff;
    font-size: 1.5rem;
  }
  /* section 1 */
  .kalkinSection1 .goToStore {
    position: absolute;
    top: 0;
    right: 0;
  }
  .kalkinSection1 .route {
    position: absolute;
    height: 300px;
    z-index: -20;
    left: calc(40%);
    bottom: -200px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .kalkinSection1 .postcard {
    position: absolute;
    text-align: center;
    opacity: 1;
    left: calc(50% - 425px);
    top: calc(50% - 200px);
    width: 850px;
    transform: rotate(-5deg);
    transition: opacity 1s, left 1s, top 1s, width 1s;
  }

  /* Transition */
  #kalkinSection1Postcard {
    top: 60%;
  }

  .kalkinSection1 .postcardTriggered .route {
    opacity: 0.8;
  }

  /* section 2 */
  .kalkinSection2 .route {
    position: absolute;
    height: 400px;
    z-index: -20;
    left: calc(30%);
    bottom: -150px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .kalkinSection2 .text1 {
    position: absolute;
    width: 350px;
    z-index: -1;
    /* font-size: 2.5rem; */
    right: calc(12%);
    top: 50px;
    opacity: 0;
    transition: opacity 1s;
  }
  .kalkinSection2 .text2 {
    position: absolute;
    width: 350px;
    z-index: -1;
    /* font-size: 2.5rem; */
    right: calc(12%);
    bottom: 50px;
    opacity: 0;
    transition: opacity 1s;
  }

  .kalkinSection2 .kochimap {
    position: absolute;
    z-index: -20;
    height: 600px;
    opacity: 0;
    transform: rotate(20deg);
    left: -1000px;
    bottom: -220px;
    transition: opacity 1s, transform 1.2s, left 1s, bottom 1s;
  }
  .kalkinSection2 .kochipostcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    transform: rotate(30deg);
    opacity: 0;
    left: -1000px;
    top: -1000px;
    transition: opacity 1s, transform 1.2s, left 2s, top 2s;
  }
  /* Transition */
  #kalkinSection2Text1Trigger {
    top: 20%;
  }
  #kalkinSection2Text2Trigger {
    top: 40%;
  }
  #kalkinSection2MapTrigger {
    top: 30%;
  }
  .kalkinSection2 .textTriggered {
    opacity: 1;
  }
  .kalkinSection2 .mapTriggered > .kochimap {
    opacity: 1;
    bottom: calc(50% - 300px);
    left: calc(12%);
    transform: rotate(0deg);
  }
  .kalkinSection2 .mapTriggered > .kochipostcard {
    transform: rotate(10deg);
    left: calc(12%);
    top: 200px;
    opacity: 1;
  }
  .kalkinSection2 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 3 */

  .kalkinSection3 .route {
    position: absolute;
    height: 100px;
    z-index: -20;
    right: calc(35%);
    bottom: -100px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }

  .kalkinSection3 .text1 {
    position: absolute;
    width: 100%;
    z-index: -1;
    /* font-size: 3rem; */
    top: 20px;
    opacity: 0;
    transition: opacity 1s;
  }
  .kalkinSection3 .text2 {
    position: absolute;
    width: 400px;
    z-index: -1;
    /* font-size: rem; */
    top: calc(50% - 70px);
    left: calc(15%);
    opacity: 0;
    transition: opacity 1s;
  }

  .kalkinSection3 .mumbaimap {
    position: absolute;
    height: 600px;
    z-index: -1;
    transform: rotate(20deg);
    right: 1000px;
    bottom: -220px;
    opacity: 0;
    transition: opacity 1s, transform 1s, right 1s, bottom 1s;
  }
  .kalkinSection3 .mumbaipostcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    right: 2000px;
    bottom: 700px;
    transform: rotate(90deg);
    opacity: 0;
    transition: opacity 1.2s, transform 1s, right 1s, bottom 1s;
  }
  /* Transition */
  #kalkinSection3Text1Trigger {
    top: 5%;
  }
  #kalkinSection3Text2Trigger {
    top: 30%;
  }
  #kalkinSection3MapTrigger {
    top: 30%;
  }
  .kalkinSection3 .textTriggered {
    opacity: 1;
  }
  .kalkinSection3 .mapTriggered > .mumbaimap {
    opacity: 1;
    bottom: 20px;
    right: calc(5%);
    transform: rotate(0deg);
  }
  .kalkinSection3 .mapTriggered > .mumbaipostcard {
    transform: rotate(10deg);
    bottom: 80px;
    right: calc(5%);
    opacity: 1;
  }
  .kalkinSection3 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 4 */
  .kalkinSection4 .route {
    position: absolute;
    height: 225px;
    z-index: -20;
    right: calc(37.5%);
    top: calc(50% - 70px);
    opacity: 0;
    transform: rotate(0);
    transition: opacity 0.5s;
  }
  .kalkinSection4 .text1 {
    position: absolute;
    width: 350px;
    z-index: -1;
    /* font-size: 2.5rem; */
    top: 100px;
    opacity: 0;
    left: -1000px;
    transition: opacity 1.2s, left 1s;
  }
  .kalkinSection4 .text2 {
    position: absolute;
    width: 350px;
    z-index: -1;
    /* font-size: 2.5rem; */
    bottom: 100px;
    opacity: 0;
    left: -1000px;
    transition: opacity 1.2s, left 1s;
  }

  .kalkinSection4 .questionmark {
    position: absolute;
    height: 600px;
    z-index: -1;
    opacity: 0;
    right: 0;
    top: 2000px;
    transition: opacity 1s, top 1s;
  }

  /* Transition */
  #kalkinSection4TextTrigger {
    top: 5%;
  }
  #kalkinSection4Text2Trigger {
    top: 40%;
  }
  #kalkinSection4MapTrigger {
    top: 30%;
  }
  .kalkinSection4 .textTriggered {
    opacity: 1;
    left: calc(10%);
  }
  .kalkinSection4 .text2Triggered {
    opacity: 1;
    left: calc(10%);
  }
  .kalkinSection4 .mapTriggered > .questionmark {
    opacity: 1;
    right: calc(5%);
    top: calc(50% - 300px);
  }

  .kalkinSection4 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 5 */
  .kalkinSection5 .route {
    position: absolute;
    height: 275px;
    z-index: -20;
    right: calc(37.5%);
    top: -25px;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .kalkinSection5 .text1 {
    position: absolute;
    width: 300px;
    z-index: -1;
    opacity: 0;
    right: calc(50% - 300px);
    top: 100px;
    transition: opacity 1s, left 1s;
  }
  .kalkinSection5 .text2 {
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: unset;
    bottom: 0px;
    transition: opacity 1s, left 1s;
  }

  .kalkinSection5 .keralamap {
    position: absolute;
    height: 600px;
    z-index: -1;
    opacity: 0;
    left: calc(50% - 275px);
    top: 200px;
    transition: opacity 1s, top 1s, left 1s;
  }
  .kalkinSection5 .keralapostcard {
    position: absolute;
    width: 350px;
    z-index: -1;
    transform: rotate(8deg);
    top: -900px;
    opacity: 0;
    left: calc(50% - 175px);
    transition: opacity 1.2s, top 1.2s, left 1s;
  }

  /* Transition */
  #kalkinSection5TextTrigger {
    top: 5%;
  }

  #kalkinSection5MapTrigger {
    top: 10%;
  }
  .kalkinSection5 .textTriggered {
    opacity: 1;
  }
  .kalkinSection5 .mapTriggered > .keralamap {
    opacity: 1;
    left: calc(10%);
    top: calc(50% - 300px);
  }
  .kalkinSection5 .mapTriggered > .keralapostcard {
    opacity: 1;
    top: 150px;
    left: 0;
  }
  .kalkinSection5 .mapTriggered > .route {
    opacity: 0.8;
  }
}
