.Gallery {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Header {
  width: 100%;
  max-width: 960px;
  margin: 0.5rem 0;
}

.Header > h1 {
  font-size: 1.5rem;
  margin: 0rem 1rem;
}

@media all and (max-width: 1280px) {
  .Header > h1 {
    display: none;
  }
}
