/* Mobile style */
.aboutRover {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.route {
  opacity: 0.8;
}

.aboutRoverSection {
  width: 90%;
  position: relative;
  height: 170vw;
  box-sizing: border-box;
  margin: 0px 5%;
  padding-top: 1rem;
  /* debugging */
  /* border-bottom: 1px solid aquamarine;
  border-top: 1px solid aquamarine; */
}
.aboutRoverSection p {
  font-family: Reenie;
  color: white;
  font-size: 2rem;
  line-height: 3rem;
}
.trigger {
  /* debugging */
  /* height: 10px;
  width: 10px;
  background-color: aquamarine;
  border: 2px solid red;
  border-radius: 50%; */
  position: absolute;
}
.aboutRoverSection .book_intro {
  position: relative;

  /* line-height: 3.5rem; */
}
.aboutRoverSection .book_intro > * {
  line-height: 3.5rem !important;
}
.aboutRoverSection h1.title {
  font-family: Remington;
  color: #d23263;
  font-size: 3rem;
}
.aboutRoverSection .subtext {
  font-family: Remington;
  color: #ffffff;
  font-size: 1.25rem;
}

/* section 1 */
.roverSection1 .goToStore {
  all: unset;
  padding: 10px 20px;
  background-color: #d23263;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  cursor: pointer;
}
.roverSection1 .route {
  position: absolute;
  height: 350px;
  z-index: -20;
  left: calc(50% - 100px);
  transition: opacity 1s 1s;
  bottom: -250px;
}
.roverSection1 .postcard {
  position: absolute;
  text-align: center;
  transform: rotate(20deg);
  left: calc(50% - 200px);
  top: calc(50%);
  width: 400px;
}

/* Transition */
#section1Postcard {
  top: 10%;
}

/* section 2 */
.roverSection2 {
  padding-top: 1rem;
}
.roverSection2 .text1 {
  position: absolute;
  width: 325px;
  z-index: -1;
  font-size: 1.8rem;
  top: 110px;
  transition: opacity 1s;
  line-height: 2.5rem;
}

.roverSection2 .delhimap {
  position: absolute;
  z-index: -20;
  width: 400px;
  opacity: 1;
  bottom: -70px;
  right: -80px;
  transform: rotate(0deg);
}
.roverSection2 .delhipostcard {
  position: absolute;
  width: 200px;
  z-index: -1;
  transform: rotate(10deg);
  right: -30px;
  bottom: 50px;
  opacity: 1;
}
/* Transition */
#section2TextTrigger {
  top: 40%;
}
#section2MapTrigger {
  top: 60%;
}

/* section 2 */

.roverSection3 .route {
  position: absolute;
  width: 225px;
  z-index: -20;
  left: calc(50% - 112.5px);
  top: 75px;
}

.roverSection3 .text1 {
  position: absolute;
  width: 310px;
  z-index: -1;
  font-size: 1.6rem;
  top: 50px;
}

.roverSection3 .tharmap {
  position: absolute;
  width: 450px;
  z-index: -1;
  opacity: 1;
  bottom: -50px;
  left: -80px;
  transform: rotate(0deg);
}
.roverSection3 .tharpostcard {
  position: absolute;
  width: 200px;
  z-index: -1;
  transform: rotate(-5deg);
  bottom: 30px;
  left: 45px;
  opacity: 1;
}
/* Transition */
#section3TextTrigger {
  top: 5%;
}
#section3MapTrigger {
  top: 30%;
}

/* section 3 */
.roverSection4 .route {
  position: absolute;
  width: 150px;
  z-index: -20;
  left: calc(50% - 80px);
  top: 70px;

  transition: opacity 1s 1s;
}
.roverSection4 .text1 {
  position: absolute;
  width: 350px;
  z-index: -1;
  top: 75px;
  left: 0;
  font-size: 1.75rem;
  line-height: 37.5px;
}
.roverSection4 .text2 {
  position: absolute;
  width: 310px;
  z-index: -1;
  top: 350px;
  left: 0;
  font-size: 1.75rem;
  line-height: 37.5px;
}

.roverSection4 .jaipurmap {
  position: absolute;
  width: 350px;
  z-index: -1;
  right: -50px;
  bottom: -150px;
}
.roverSection4 .jaipurpostcard {
  position: absolute;
  width: 210px;
  z-index: -1;
  right: calc(50% - 100px);
  bottom: 345px;
  transform: rotate(-10deg);
}
/* Transition */
#section4TextTrigger {
  top: 5%;
}
#section4Text2Trigger {
  top: 30%;
}
#section4MapTrigger {
  top: 40%;
}

/* section 5 */
.roverSection5 {
  margin-bottom: 2rem !important;
}

.roverSection5 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  right: 160px;
  top: 80px;
  transition: opacity 1s 1s;
}
.roverSection5 .text1 {
  position: absolute;
  text-align: center;
  width: 300px;
  z-index: -1;
  font-size: 1.5rem;
  left: calc(50% - 150px);
  top: 170px;
}

.roverSection5 .udaipurmap {
  position: absolute;
  width: 300px;
  z-index: -1;
  left: calc(50% - 160px);
  bottom: 0px;
}
.roverSection5 .udaipurpostcard {
  position: absolute;
  width: 210px;
  z-index: -1;
  transform: rotate(8deg);
  bottom: 170px;
  left: calc(50% - 40px);
}

/* Transition */
#section5TextTrigger {
  top: 5%;
}
#section5MapTrigger {
  top: 30%;
}
