.tab_wrapper {
  width: 100%;
}
.tab_container {
}

.tab_headers {
  height: 3rem;
  display: inline-block;
  display: flex;
  background-color: #312e31;
  border: 1px solid;
  border-color: #121112;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
}
.tab_header {
  padding: 15px;
  color: white;
  background-color: #2c282c;
}
.tab_header:hover {
  background-color: #3a353a;
}

.tab_contents {
  background-color: #312e31;
  border: 1px solid;
  border-top: unset;
  border-color: #121112;
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  padding: 15px 15px;
}
