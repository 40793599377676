/* Mobile */
.landing_text_wrapper {
  width: 100%;
  position: absolute;
  left: 5px;
  top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing_text_container {
  padding: 0 10%;
}

.landing_text_container * {
  padding: 0.3rem 0;
  font-family: Caveat;
  font-size: 1.75rem;
  color: #e6ddce;
  letter-spacing: 2px;
  z-index: -1;
}
.landing_text_container *:first-child {
  font-family: Caveat;
  font-size: 2rem;
}
.landing_container {
  padding-top: 10rem;
  position: relative;
  /* height: 70vh; */
}

.landing_container img {
  display: block;
}
.section_container {
  box-sizing: border-box;
  padding: 3rem 0;
  position: relative;
}

.section_container p {
  font-size: 1rem;
}

.content1_container {
  background-color: #d23263;
}

.content3_container::after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  z-index: -5;
  background-image: url(../../../assets/images/red_fort.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.content3_container {
  background-color: #ff8400;
  z-index: 0;
  background-position: center;
  background-size: auto;
}

.content_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content1 {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* font-family: Roboto; */
  font-weight: 200;
  font-size: 2em;
  letter-spacing: 0.1em;
  text-align: center;
  color: white;
}
.content1 > img {
  box-sizing: border-box;
  padding: 1rem 0;
  width: 15rem;
}

.content1 > p {
  width: 100%;
}

.content2 {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  /* font-family: Roboto; */
  font-weight: 200;
  font-size: 2em;
  letter-spacing: 0.1em;
  text-align: center;
  color: white;
}

.content2 > img {
  box-sizing: border-box;
  padding: 1rem 0;
  width: 15rem;
}

.content2 > p {
  width: 100%;
}
.content3 {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-family: Roboto; */
  font-weight: 400;
  font-size: 1.3em;
  letter-spacing: 0.1em;
  text-align: center;
  color: white;
}
.content3 p {
  z-index: 1;
}
.footer {
  height: 5em;
}

.custom-shape-divider-bottom-1641595024 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1641595024 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 97px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1641595024 .shape-fill {
  fill: #d23263;
}

.custom-shape-divider-top-1641595828 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1641595828 svg {
  position: relative;
  display: block;
  width: calc(167% + 1.3px);
  height: 63px;
}

.custom-shape-divider-top-1641595828 .shape-fill {
  fill: #d23263;
}

.custom-shape-divider-bottom-1641595891 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1641595891 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 49px;
}

.custom-shape-divider-bottom-1641595891 .shape-fill {
  fill: #ff8400;
}
.custom-shape-divider-top-1641595930 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1641595930 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 37px;
}

.custom-shape-divider-top-1641595930 .shape-fill {
  fill: #ff8400;
}
