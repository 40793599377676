.footer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #1a181b;
  margin-top: auto;
}

.footer_container {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  flex-direction: column;
  letter-spacing: 0.25rem;
  gap: 10px;
}
.footer_container > ul {
  display: flex;
}
.footer_container > ul > li {
  list-style: none;
  margin: 0 1rem 0 1rem;
}
.footer_container > ul > li > h2 {
  /* font-family: Roboto; */
  font-weight: 200;
  font-size: 1rem;
  text-align: center;
}
.footer_container > h3 {
  font-weight: 500;
  text-align: center;
}
