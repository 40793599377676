/* Mobile style */
.aboutAditi {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center {
  text-align: center;
}
.route {
  opacity: 0.8;
}

.aboutAditiSection .book_intro {
  position: relative;
}

.aboutAditiSection {
  width: 90%;
  position: relative;
  height: 170vw;
  box-sizing: border-box;
  margin: 0px 5%;
  padding-top: 1rem;
  /* debugging */
  /* border-bottom: 1px solid aquamarine;
  border-top: 1px solid aquamarine;
  box-sizing: border-box; */
}
.aboutAditiSection p {
  font-family: Reenie;
  color: white;
  font-size: 2rem;
  line-height: 3.5rem;
}
.trigger {
  /* debugging */
  /* height: 10px;
  width: 10px;
  background-color: aquamarine;
  border: 2px solid red;
  border-radius: 50%; */
  position: absolute;
}

.aboutAditiSection p.title {
  position: relative;
  z-index: -1;
  font-family: Remington;
  color: #ff8400;
  font-size: 3rem;
}
.aboutAditiSection p.subtext {
  position: relative;
  z-index: -1;
  font-family: Remington;
  color: #ffffff;
  font-size: 1.25rem;
}

/* section 1 */
.aditiSection1 .goToStore {
  all: unset;
  padding: 10px 20px;
  background-color: #ff8400;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  cursor: pointer;
}
.aditiSection1 .route {
  position: absolute;
  height: 125px;
  z-index: -20;
  right: calc(50% - 60px);
  bottom: -110px;
  transition: opacity 1s 1s;
  transform: rotate(-120deg);
}
.aditiSection1 .postcard {
  position: absolute;
  text-align: center;
  width: 120px;
  transform: rotate(20deg);
  left: calc(50% - 200px);
  top: calc(50%);
  width: 400px;
}

/* Transition */
#aditiSection1Postcard {
  top: 10%;
}

/* section 2 */
/* .aditiSection2 {
  padding-top: 1rem;
} */
.aditiSection2 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  right: calc(50% - 50px);
  bottom: -120px;
  transition: opacity 1s 1s;
  transform: rotate(170deg);
}
.aditiSection2 .text1 {
  position: absolute;
  width: 325px;
  z-index: -1;

  top: 10px;
  transition: opacity 1s;
}
.aditiSection2 .text2 {
  position: absolute;
  width: 325px;
  z-index: -1;
  /* font-size: 1.8rem; */
  bottom: 10px;
  transition: opacity 1s;
}

.aditiSection2 .Major {
  position: absolute;
  z-index: -20;
  height: 250px;
  opacity: 1;
  bottom: calc(50% - 125px);
  right: calc(50% - 95px);
  transform: rotate(0deg);
}

/* Transition */
#aditiSection2Text1Trigger {
  top: 40%;
}
#aditiSection2Text2Trigger {
  top: 40%;
}
#aditiSection2MapTrigger {
  top: 60%;
}

/* section 3 */

.aditiSection3 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  left: calc(50% - 80px);
  bottom: 50px;
  transform: rotate(-20deg);
}

.aditiSection3 .text1 {
  position: absolute;
  width: 100%;
  z-index: -1;
  /* font-size: 1.6rem; */
  top: calc(100% - 350px);
}

.aditiSection3 .Stone {
  position: absolute;
  width: 300px;
  z-index: -1;
  opacity: 1;
  bottom: calc(100% - 220px);
  right: calc(50% - 150px);
  transform: rotate(0deg);
}

/* Transition */
#aditiSection3TextTrigger {
  top: 5%;
}

#aditiSection3MapTrigger {
  top: 30%;
}

/* section 3 */
.aditiSection4 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  left: calc(50% - 50px);
  bottom: 0px;
  transform: rotate(20deg);
  transition: opacity 1s 1s;
}
.aditiSection4 .text1 {
  text-align: center;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: calc(100% - 300px);
  right: 0;
  /* font-size: 1.75rem; */
}

.aditiSection4 .kochi_map {
  position: absolute;
  height: 300px;
  z-index: -1;
  left: calc(50% - 85px);
  bottom: calc(100% - 325px);
}
.aditiSection4 .kochi_postcard {
  position: absolute;
  width: 200px;
  z-index: -1;
  left: calc(50% - 85px);
  bottom: calc(100% - 275px);
}

/* Transition */
#aditiSection4TextTrigger {
  top: 5%;
}
#aditiSection4MapTrigger {
  top: 40%;
}

/* section 5 */
/* .aditiSection5 {
  margin-bottom: 2rem !important;
} */

.aditiSection5 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  right: calc(50% - 70px);
  bottom: 50px;
  transition: opacity 1s 1s;
}
.aditiSection5 .text1 {
  position: absolute;
  width: 100%px;
  z-index: -1;
  /* font-size: 1.5rem; */
  bottom: 0px;
}

.aditiSection5 .Sarcophagus {
  position: absolute;
  width: 200px;
  z-index: -1;
  left: calc(50% - 100px);
  top: 25px;
}

/* Transition */
#aditiSection5TextTrigger {
  top: 5%;
}

#aditiSection5MapTrigger {
  top: 30%;
}

/* section 6 */
.aditiSection6 .route {
  position: absolute;
  height: 350px;
  z-index: -20;
  right: calc(50% - 50px);
  bottom: -120px;
  transform: rotate(160deg);
  transition: opacity 1s 1s;
}
.aditiSection6 .text1 {
  position: absolute;
  width: 350px;
  z-index: -1;
  top: calc(100% - 350px);
  left: 0;
  /* font-size: 1.75rem; */
}

.aditiSection6 .kolkata_map {
  position: absolute;
  height: 300px;
  z-index: -1;
  right: calc(50% - 90px);
  bottom: calc(100% - 275px);
  transform: rotate(10deg);
}
.aditiSection6 .kolkata_postcard {
  position: absolute;
  width: 150px;
  z-index: -1;
  right: calc(50% - 130px);
  bottom: calc(100% - 130px);
}

/* section 7 */
.aditiSection7 .route {
  position: absolute;
  width: 225px;
  z-index: -20;
  left: calc(50% - 112.5px);
  top: 75px;
}

.aditiSection7 .text1 {
  position: absolute;
  width: 100%;
  z-index: -1;
  /* font-size: 1.6rem; */
  top: 75px;
}

.aditiSection7 .Girl {
  position: absolute;
  width: 300px;
  z-index: -1;
  opacity: 1;
  bottom: 20px;
  right: 0px;
  transform: rotate(0deg);
}

/* Transition */
#aditiSection7TextTrigger {
  top: 5%;
}

#aditiSection7MapTrigger {
  top: 30%;
}
