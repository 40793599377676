/* Desktop style */

@media all and (min-width: 700px) {
  .aboutRoverSection {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    margin: 0px 20%;
    max-width: 950px;
    max-height: 765px;
  }
  .aboutRoverSection p {
    font-family: Reenie;
    color: white;
    font-size: 2rem;
    line-height: 3rem;
  }
  .trigger {
    position: absolute;
  }
  .aboutRoverSection .book_intro {
    position: absolute;
    left: 0;
    top: 0;
    /* line-height: 3.5rem; */
  }
  .aboutRoverSection .book_intro > h1 {
    line-height: 3.5rem !important;
  }
  .aboutRoverSection h1.title {
    position: relative;
    z-index: -1;
    font-family: Remington;
    color: #d23263;
    font-size: 5rem;
  }
  .aboutRoverSection p.subtext {
    margin-top: 10px;
    position: relative;
    z-index: -1;
    font-family: Remington;
    color: #ffffff;
    font-size: 1.5rem;
  }
  .roverSection2 {
    height: 80vh;
  }

  /* section 1 */
  .roverSection1 .goToStore {
    position: absolute;
    top: 0;
    right: 0;
  }
  .roverSection1 .route {
    position: absolute;
    height: 150px;
    z-index: -20;
    left: calc(50% - 7.5px);
    bottom: -100px;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .roverSection1 .postcard {
    position: absolute;
    text-align: center;
    opacity: 1;
    left: calc(50% - 325px);
    top: calc(50% - 100px);
    width: 700px;
    transform: rotate(20deg);
    transition: opacity 1s, left 1s, top 1s, width 1s;
  }

  /* Transition */
  #roverSection1Postcard {
    top: 60%;
  }

  .roverSection1 .postcardTriggered .route {
    opacity: 0.8;
  }

  /* section 2 */
  .roverSection2 .text1 {
    position: absolute;
    width: 350px;
    z-index: -1;
    font-size: 2.5rem;
    top: 100px;
    opacity: 0;
    transition: opacity 1s;
  }

  .roverSection2 .delhimap {
    position: absolute;
    z-index: -20;
    width: 550px;
    opacity: 0;
    transform: rotate(20deg);
    right: -100px;
    bottom: -220px;
    transition: opacity 1s, transform 1s, right 1s, bottom 1s;
  }
  .roverSection2 .delhipostcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    transform: rotate(30deg);
    opacity: 0;
    right: -100px;
    bottom: 0px;
    transition: opacity 1s, transform 2s, right 2s, bottom 2s;
  }
  /* Transition */
  #section2TextTrigger {
    top: 0%;
  }
  #section2MapTrigger {
    top: 30%;
  }
  .roverSection2 .textTriggered {
    opacity: 1;
  }
  .roverSection2 .mapTriggered > .delhimap {
    opacity: 1;
    bottom: -30px;
    right: 0px;
    transform: rotate(0deg);
  }
  .roverSection2 .mapTriggered > .delhipostcard {
    transform: rotate(10deg);
    right: 0px;
    bottom: 159px;
    opacity: 1;
  }

  /* section 3 */

  .roverSection3 .route {
    position: absolute;
    width: 250px;
    z-index: -20;
    left: calc(50% - 125px);
    top: 15px;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .roverSection3 .text1 {
    position: absolute;
    width: 450px;
    z-index: -1;
    font-size: 2.5rem;
    top: 20px;
    opacity: 0;
    transition: opacity 1s;
  }

  .roverSection3 .tharmap {
    position: absolute;
    width: 550px;
    z-index: -1;
    transform: rotate(20deg);
    left: 1000px;
    bottom: -220px;
    opacity: 0;
    transition: opacity 1s, transform 1s, left 1s, bottom 1s;
  }
  .roverSection3 .tharpostcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    left: 2000px;
    bottom: 700px;
    transform: rotate(90deg);
    opacity: 0;
    transition: opacity 2s, transform 1s, left 1s, bottom 1s;
  }
  /* Transition */
  #section3TextTrigger {
    top: 5%;
  }
  #section3MapTrigger {
    top: 30%;
  }
  .roverSection3 .textTriggered {
    opacity: 1;
  }
  .roverSection3 .mapTriggered > .tharmap {
    opacity: 1;
    bottom: 20px;
    left: 100px;
    transform: rotate(0deg);
  }
  .roverSection3 .mapTriggered > .tharpostcard {
    transform: rotate(10deg);
    bottom: 80px;
    left: 275px;
    opacity: 1;
  }
  .roverSection3 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 4 */
  .roverSection4 .route {
    position: absolute;
    width: 140px;
    z-index: -20;
    left: calc(50% - 125px);
    top: 20px;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .roverSection4 .text1 {
    position: absolute;
    width: 400px;
    z-index: -1;
    font-size: 2.5rem;
    top: 100px;
    opacity: 0;
    left: -1000px;
    transition: opacity 2s, left 1s;
  }
  .roverSection4 .text2 {
    position: absolute;
    width: 350px;
    z-index: -1;
    font-size: 2.5rem;
    top: 350px;
    opacity: 0;
    left: -1000px;
    transition: opacity 2s, left 1s;
  }

  .roverSection4 .jaipurmap {
    position: absolute;
    width: 475px;
    z-index: -1;
    opacity: 0;
    right: 0;
    top: 2000px;
    transition: opacity 1s, top 1s;
  }
  .roverSection4 .jaipurpostcard {
    position: absolute;
    width: 350px;
    z-index: -1;
    right: 100px;
    bottom: 800px;
    opacity: 0;
    transform: rotate(-10deg);
    transition: opacity 1s, bottom 1.5s;
  }
  /* Transition */
  #section4TextTrigger {
    top: 5%;
  }
  #section4Text2Trigger {
    top: 30%;
  }
  #section4MapTrigger {
    top: 40%;
  }
  .roverSection4 .textTriggered {
    opacity: 1;
    left: 0px;
  }
  .roverSection4 .text2Triggered {
    opacity: 1;
    left: 0px;
  }
  .roverSection4 .mapTriggered > .jaipurmap {
    opacity: 1;
    right: 0;
    top: calc(50% - 237.5px);
  }
  .roverSection4 .mapTriggered > .jaipurpostcard {
    opacity: 1;
    right: 100px;
    bottom: 100px;
    transform: rotate(-10deg);
  }
  .roverSection4 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 5 */
  .roverSection5 .route {
    position: absolute;
    height: 350px;
    z-index: -20;
    right: 300px;
    top: -70px;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .roverSection5 .text1 {
    position: absolute;
    text-align: center;
    width: 500px;
    z-index: -1;
    font-size: 2.5rem;
    opacity: 0;
    left: 1000px;
    top: 50px;
    transition: opacity 1s, left 1s;
  }

  .roverSection5 .udaipurmap {
    position: absolute;
    width: 550px;
    z-index: -1;
    opacity: 0;
    left: calc(50% - 275px);
    bottom: 800px;
    transition: opacity 1s, bottom 1s;
  }
  .roverSection5 .udaipurpostcard {
    position: absolute;
    width: 350px;
    z-index: -1;
    transform: rotate(8deg);
    bottom: -900px;
    opacity: 0;
    left: calc(50% - 175px);
    transition: opacity 2s, bottom 2s;
  }

  /* Transition */
  #section5TextTrigger {
    top: 5%;
  }
  #section5MapTrigger {
    top: 30%;
  }
  .roverSection5 .textTriggered {
    opacity: 1;
    left: calc(50% - 250px);
    top: 50px;
  }
  .roverSection5 .mapTriggered > .udaipurmap {
    opacity: 1;
    left: calc(50% - 275px);
    bottom: 0px;
  }
  .roverSection5 .mapTriggered > .udaipurpostcard {
    opacity: 1;
    bottom: 150px;
    left: calc(50% - 175px);
  }
  .roverSection5 .mapTriggered > .route {
    opacity: 0.8;
  }
}
