.Banner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100vw;
  position: relative;
  top: 0;
  color: white;
  box-sizing: border-box;
  padding: 0.5rem;
  background-color: #b7385f;
  text-align: center;
}
.Banner a {
  color: #c9bd38;
}
