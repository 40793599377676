/* Mobile style */
.aboutKalkin {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.center {
  text-align: center;
}
.route {
  opacity: 0.8;
}

.aboutKalkinSection {
  width: 90%;
  position: relative;
  height: 170vw;
  box-sizing: border-box;
  margin: 0px 5%;
  padding-top: 1rem;
  /* debugging */
  /* border-bottom: 1px solid aquamarine;
  border-top: 1px solid aquamarine; */
}
.aboutKalkinSection p {
  font-family: Reenie;
  color: white;
  font-size: 2rem;
  line-height: 2.5rem;
}
.trigger {
  /* debugging */
  /* height: 10px;
  width: 10px;
  background-color: aquamarine;
  border: 2px solid red;
  border-radius: 50%; */
  position: absolute;
}
.aboutKalkinSection .book_intro {
  position: relative;

  line-height: 3.5rem;
}

.aboutKalkinSection .book_intro > * {
  line-height: 3.5rem !important;
}
.aboutKalkinSection h1.title {
  font-family: Remington;
  color: #2274a5;
  font-size: 3rem;
}
.aboutKalkinSection .subtext {
  font-family: Remington;
  color: #ffffff;
  font-size: 1.25rem;
}

/* section 1 */
.kalkinSection1 .goToStore {
  all: unset;
  padding: 10px 20px;
  background-color: #2274a5;
  text-align: center;
  font-size: 1.5rem;
  position: relative;
  cursor: pointer;
}
.kalkinSection1 .route {
  position: absolute;
  height: 275px;
  z-index: -20;
  left: calc(50% - 50px);
  bottom: -220px;
  transition: opacity 1s 1s;
  transform: rotate(-20deg);
}
.kalkinSection1 .postcard {
  position: absolute;
  text-align: center;
  transform: rotate(-20deg);
  left: calc(50% - 200px);
  top: calc(50%);
  width: 400px;
}

/* Transition */
#kalkinSection1Postcard {
  top: 10%;
}

/* section 2 */
.kalkinSection2 .route {
  position: absolute;
  height: 300px;
  z-index: -20;
  left: calc(50% - 90px);
  bottom: -150px;
  transition: opacity 1s 1s;
  transform: rotate(30deg);
}
.kalkinSection2 {
  padding-top: 1rem;
}
.kalkinSection2 .text1 {
  position: absolute;
  width: 325px;
  z-index: -1;
  /* font-size: 1.8rem; */
  top: 0px;
  transition: opacity 1s;
}
.kalkinSection2 .text2 {
  position: absolute;
  width: 325px;
  z-index: -1;
  /* font-size: 1.8rem; */
  bottom: 0px;
  transition: opacity 1s;
}

.kalkinSection2 .kochimap {
  position: absolute;
  z-index: -20;
  height: 250px;
  opacity: 1;
  bottom: calc(50% - 180px);
  right: calc(50% - 70px);
  transform: rotate(0deg);
}
.kalkinSection2 .kochipostcard {
  position: absolute;
  width: 150px;
  z-index: -1;
  transform: rotate(10deg);
  bottom: calc(50% - 130px);
  right: calc(50% - 100px);
  opacity: 1;
}
/* Transition */
#kalkinSection2Text1Trigger {
  top: 40%;
}
#kalkinSection2Text2Trigger {
  top: 40%;
}
#kalkinSection2MapTrigger {
  top: 60%;
}

/* section 3 */

.kalkinSection3 .route {
  position: absolute;
  height: 80px;
  z-index: -20;
  right: calc(50% - 20px);
  bottom: calc(50% - 100px);
  transform: rotate(-30deg);
}

.kalkinSection3 .text1 {
  position: absolute;
  width: 100%;
  z-index: -1;
  /* font-size: 1.6rem; */
  top: 25px;
}
.kalkinSection3 .text2 {
  position: absolute;
  width: 310px;
  z-index: -1;
  /* font-size: 1.6rem; */
  top: calc(50%);
}

.kalkinSection3 .mumbaimap {
  position: absolute;
  height: 300px;
  z-index: -1;
  opacity: 1;
  bottom: -150px;
  right: calc(50% - 90px);
  transform: rotate(0deg);
}
.kalkinSection3 .mumbaipostcard {
  position: absolute;
  width: 250px;
  z-index: -1;
  transform: rotate(-5deg);
  bottom: 55%;
  right: calc(50% - 125px);
  opacity: 1;
}
/* Transition */
#kalkinSection3Text1Trigger {
  top: 5%;
}
#kalkinSection3Text2Trigger {
  top: 5%;
}
#kalkinSection3MapTrigger {
  top: 30%;
}

/* section 3 */
.kalkinSection4 .route {
  position: absolute;
  height: 250px;
  z-index: -20;
  right: calc(50% - 65px);
  top: 100px;
  transition: opacity 1s 1s;
  transform: rotate(180deg);
}
.kalkinSection4 .text1 {
  position: absolute;
  width: 350px;
  z-index: -1;
  top: 150px;
  left: calc(50% - 175px);
  /* font-size: 1.75rem; */
}
.kalkinSection4 .text2 {
  position: absolute;
  width: 350px;
  z-index: -1;
  bottom: 50px;
  left: calc(50% - 175px);

  /* font-size: 1.75rem; */
}

.kalkinSection4 .questionmark {
  position: absolute;
  height: 500px;
  z-index: -1;
  right: calc(50% - 200px);
  bottom: calc(50% - 300px);
  opacity: 0.2;
}

/* Transition */
#kalkinSection4TextTrigger {
  top: 5%;
}
#kalkinSection4Text2Trigger {
  top: 30%;
}
#kalkinSection4MapTrigger {
  top: 40%;
}

/* section 5 */
.kalkinSection5 {
  margin-bottom: 2rem !important;
}

.kalkinSection5 .route {
  position: absolute;
  height: 250px;
  z-index: -20;
  right: calc(50% - 75px);
  top: -80px;
  transition: opacity 1s 1s;
}
.kalkinSection5 .text1 {
  position: absolute;
  width: 350px;
  z-index: -1;
  /* font-size: 1.5rem; */
  right: calc(50% - 175px);
  top: 0;
}
.kalkinSection5 .text2 {
  position: absolute;
  width: 350px;
  z-index: -1;
  /* font-size: 1.5rem; */
  left: calc(50% - 175px);
  bottom: 50px;
}

.kalkinSection5 .keralamap {
  position: absolute;
  height: 300px;
  z-index: -1;
  left: calc(50% - 125px);
  top: calc(50% - 150px);
}
.kalkinSection5 .keralapostcard {
  position: absolute;
  width: 200px;
  z-index: -1;
  transform: rotate(0deg);
  left: calc(50% - 125px);
  top: calc(50% - 90px);
}

/* Transition */
#kalkinSection5TextTrigger {
  top: 5%;
}

#kalkinSection5MapTrigger {
  top: 30%;
}
