.reviewsPage_wrapper {
  min-height: 70vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reviewsPage_container {
  height: 100%;
  width: 100%;
  max-width: 950px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.reviewsPage_review_container {
  height: 200px;
  width: 100%;
  background-color: #211f21;
  border: 1px solid;
  border-color: #121112;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 10%;
  box-sizing: border-box;
}
.reviewsPage_review_form_wrapper {
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  /* height: 4rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #211f21;
  border: 1px solid;
  border-color: #121112;
  border-radius: 5px;
  margin-bottom: 1rem;
  flex-direction: column;
}
.reviewsPage_review_form_container {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.reviewsPage_review_form_container input,
.reviewsPage_review_form_container button,
.reviewsPage_review_form_container textarea,
.reviewsPage_review_form_container select {
  all: unset;
  border: white;
  /* height: 2rem; */
  color: white;
  background-color: #312e31;
  border: 1px solid;
  border-color: #121112;
}
.reviewsPage_review_form_container input,
.reviewsPage_review_form_container textarea,
.reviewsPage_review_form_container select {
  width: 100%;
  font-size: 1.25rem;
  box-sizing: border-box;
  padding: 1rem 2rem;
}
.reviewsPage_review_form_container textarea {
  margin-bottom: 0.5rem;
  min-height: 150px;
  overflow-y: scroll;
  resize: none;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.review_form_name_input {
  width: 50%;
}
.reviewsPage_review_form_container select {
  width: 50%;
}
.reviewsPage_review_form_container button {
  text-align: center;
  padding: 1rem 2rem;
}
.review_form_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  color: rgb(143, 143, 143);
}
@media all and (max-width: 700px) {
  .review_form_row {
    flex-direction: column;
  }
  .review_form_row p {
    text-align: center;
  }
  .review_form_name_input {
    width: 100%;
  }
  .reviewsPage_review_form_container select {
    width: 100%;
  }
}
