.Stars ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.Stars li {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Stars img {
  color: white;
}

@media all and (max-width: 700px) {
  .Stars ul {
    justify-content: center;
  }
}
