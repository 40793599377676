.review_wrapper {
  margin: 2rem 0;
  width: 100%;
  color: white;
}
.review_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.review_container .review_title {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 1rem;
}
.review_container .review_title p {
  font-size: 1.25rem;
  font-weight: 500;
}

/* .review_container .review_content {
} */
.review_container .review_content p {
  font-size: 1rem;
  font-weight: 300;
}

/* .review_container .review_title h1 {
  margin: 1rem 0 1rem 0;
  font-size: 2rem;
  font-weight: 500;
}
.review_container .review_title h2 {
  font-size: 1.5rem;
  font-weight: 200;
}
.review_container .review_title  h3 {
  font-size: 1.25rem;
  font-weight: 500;
} */
