.admincontrols_wrapper {
  width: 100%;
}

.admincontrols_container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  padding: 15px 15px;
  color: white;
  box-sizing: border-box;
}
