.login_wrapper {
  width: 100%;
}

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;

  position: relative;
  /* z-index: 5; */
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 3rem;
}

.form > * {
  all: unset;
  margin-bottom: 1rem;
  border: white;
  height: 2rem;
  width: 80%;
  color: white;
  background-color: #312e31;
  border: 1px solid;
  border-color: #121112;
}
.form > input {
  box-sizing: border-box;
  padding-left: 2rem;
}
.form > button {
  text-align: center;
}
