.Footer {
  bottom: 0;
  letter-spacing: 0.1rem;
  color: #303a52;

  -webkit-box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%;
}
.Footer_Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 20%;

  background-color: #312e31;
  color: white;
}
.Footer_Container {
  padding: 0 30px;
  height: 100%;
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.Footer_Column_Wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2rem;
}
.Footer_Copyright {
  color: white;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #211f21;

  font-size: 0.75rem;
  font-weight: 200;
}
.Footer_Copyright p {
  margin: 0;
}

.Footer_Column {
  min-width: 150px;
  height: 70%;
  display: flex;
  flex-direction: column;
  font-weight: 200;
  /* justify-content: space-around; */
  gap: 10px;
}
.Footer_Column > p {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.Footer_Column a {
  text-decoration: none;
}
.Footer_Logo {
  display: flex;
  align-items: center;
}
.Footer_Logo img {
  width: 80px;
  margin-bottom: 1rem;
}

/* Desktop */
@media only screen and (min-width: 700px) {
  .Footer_Wrapper {
    height: 200px;
    padding: 2rem 0;
  }
  .Footer_Container {
    flex-direction: row;
    gap: unset;
  }
  .Footer_Column_Wrapper {
    flex-direction: row;
    align-items: center;

    gap: unset;
  }

  .Footer_Column > p {
    margin-bottom: 1rem;
  }
  .Footer_Logo {
    align-items: flex-start;
  }
}
