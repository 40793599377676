.admin_wrapper {
  width: 100%;
}
.admin_container {
  margin: 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.admin_info {
  position: relative;
  z-index: 5;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
  background-color: #211f21;
  border: 1px solid;
  border-color: #121112;
  border-radius: 5px;
}

.admin_info > p {
  padding: 15px 15px;
}

.admin_content {
  width: 100%;
  position: relative;
  z-index: 5;
  margin-bottom: 2rem;
  display: flex;
  background-color: #211f21;
  border: 1px solid;
  border-color: #121112;
  border-radius: 5px;
}

.admin_logout {
  all: unset;
  padding: 15px 15px;
  color: white;
}
