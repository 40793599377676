@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Thin.ttf);
  font-weight: 200;
}
@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: Reenie;
  src: url(./assets/fonts/ReenieBeanie-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Slab;
  src: url(./assets/fonts/RobotoSlab-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: Caveat;
  src: url(./assets/fonts/Caveat-Bold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: Remington;
  src: url(./assets/fonts/vtRemingtonPortable.ttf);
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Remington;
  color: #e6ddce;
  background-color: #222024;
  background-image: url('./assets/images/stars.png');
}
