/* Desktop and Tablet */
@media all and (min-width: 960px) {
  .landing_text_container {
    width: 950px;
    height: 100%;
    margin: 0;
  }

  .landing_text_container * {
    padding: 0.1rem 0;
    font-family: Caveat;
    font-size: 2.5rem;
    letter-spacing: 0.1em;
    color: #e6ddce;
    letter-spacing: 2px;
    z-index: -1;
  }

  .landing_text_container *:first-child {
    font-family: Caveat;
    font-size: 4rem;
  }
  .landing_container {
    padding-top: 0rem;
  }
  .section_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30em;
    position: relative;
  }
  .section_container p {
    font-size: 1.25rem;
  }

  .content1_container {
    background-color: #d23263;
  }

  .content3_container::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url(../../../assets/images/red_fort.png);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  .content3_container {
    background-color: #ff8400;
    background-position: center;
    background-size: auto;
  }

  .content_wrapper {
    max-width: 950px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-family: Roboto; */
    font-weight: 200;
    font-size: 2em;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
    flex-direction: row;
  }
  .content1 > img {
    width: 12em;
  }

  .content1 > p {
    width: 60%;
  }

  .content2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-family: Roboto; */
    font-weight: 200;
    font-size: 2em;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
    flex-direction: row;
  }

  .content2 > img {
    width: 12em;
  }

  .content2 > p {
    width: 60%;
  }
  .content3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font-family: Roboto; */
    font-weight: 400;
    font-size: 1.3em;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
    flex-direction: row;
  }

  .footer {
    height: 5em;
  }

  .custom-shape-divider-bottom-1641595024 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1641595024 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 97px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider-bottom-1641595024 .shape-fill {
    fill: #d23263;
  }

  .custom-shape-divider-top-1641595828 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1641595828 svg {
    position: relative;
    display: block;
    width: calc(167% + 1.3px);
    height: 63px;
  }

  .custom-shape-divider-top-1641595828 .shape-fill {
    fill: #d23263;
  }

  .custom-shape-divider-bottom-1641595891 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1641595891 svg {
    position: relative;
    display: block;
    width: calc(124% + 1.3px);
    height: 49px;
  }

  .custom-shape-divider-bottom-1641595891 .shape-fill {
    fill: #ff8400;
  }
  .custom-shape-divider-top-1641595930 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1641595930 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 37px;
  }

  .custom-shape-divider-top-1641595930 .shape-fill {
    fill: #ff8400;
  }
}
