/* Desktop style */

@media all and (min-width: 700px) {
  .aboutAditiSection {
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    margin: 0px 20%;
    max-width: 950px;
    max-height: 765px;
  }
  .aboutAditiSection p {
    font-family: Reenie;
    color: white;
    font-size: 3rem;
  }

  .aboutAditiSection .book_title {
    position: absolute;
    left: 0;
    top: 0;
  }
  .aboutAditiSection p.title {
    position: relative;
    z-index: -1;
    font-family: Remington;
    font-size: 5rem;
  }
  .aboutAditiSection p.subtext {
    margin-top: 10px;
    position: relative;
    z-index: -1;
    font-family: Remington;
    color: #ffffff;
    font-size: 1.5rem;
  }
  /* .aditiSection2 {
    height: 80vh;
  } */

  /* section 1 */
  .aditiSection1 .goToStore {
    position: absolute;
    top: 0;
    right: 0;
  }
  .aditiSection1 .route {
    position: absolute;
    height: 150px;
    z-index: -20;
    right: calc(35%);
    bottom: -50px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .aditiSection1 .postcard {
    position: absolute;
    text-align: center;
    opacity: 1;
    left: calc(50% - 425px);
    top: calc(50% - 200px);
    width: 850px;
    transform: rotate(-5deg);
    transition: opacity 1s, left 1s, top 1s, width 1s;
  }

  /* Transition */
  #aditiSection1Postcard {
    top: 60%;
  }
  .aditiSection1 .postcardTriggered .route {
    opacity: 0.8;
  }

  /* section 2 */
  .aditiSection2 .route {
    position: absolute;
    height: 400px;
    z-index: -20;
    right: calc(30%);
    bottom: -300px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .aditiSection2 .text1 {
    position: absolute;
    width: 400px;
    z-index: -1;
    /* font-size: 2.5rem; */
    left: calc(5%);
    top: 150px;
    opacity: 0;
    transition: opacity 1s;
  }
  .aditiSection2 .text2 {
    position: absolute;
    width: 400px;
    z-index: -1;
    /* font-size: 2.5rem; */
    left: calc(5%);
    bottom: 150px;
    opacity: 0;
    transition: opacity 1s;
  }

  .aditiSection2 .Major {
    position: absolute;
    z-index: -20;
    height: 550px;
    opacity: 0;
    transform: rotate(20deg);
    right: -1000px;
    bottom: -220px;
    transition: opacity 1s, transform 1.2s, right 1s, bottom 1s;
  }
  /* Transition */
  #aditiSection2Text1Trigger {
    top: 20%;
  }
  #aditiSection2Text2Trigger {
    top: 40%;
  }
  #aditiSection2MapTrigger {
    top: 30%;
  }
  .aditiSection2 .textTriggered {
    opacity: 1;
  }
  .aditiSection2 .mapTriggered > .Major {
    opacity: 1;
    bottom: calc(50% - 275px);
    right: calc(5%);
    transform: rotate(0deg);
  }
  .aditiSection2 .mapTriggered .route {
    opacity: 0.8;
  }
  /* section 3 */

  .aditiSection3 .route {
    position: absolute;
    height: 250px;
    z-index: -20;
    left: calc(50% - 125px);
    bottom: -220px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }

  .aditiSection3 .text1 {
    position: absolute;
    width: 100%;
    z-index: -1;
    /* font-size: 3rem; */
    top: 100px;
    opacity: 0;
    transition: opacity 1s;
  }

  .aditiSection3 .Stone {
    position: absolute;
    width: 600px;
    z-index: -1;
    transform: rotate(20deg);
    right: 1000px;
    bottom: -220px;
    opacity: 0;
    transition: opacity 1s, transform 1s, right 1s, bottom 1s;
  }

  /* Transition */
  #aditiSection3TextTrigger {
    top: 5%;
  }
  #aditiSection3MapTrigger {
    top: 30%;
  }
  .aditiSection3 .textTriggered {
    opacity: 1;
  }
  .aditiSection3 .mapTriggered > .Stone {
    opacity: 1;
    bottom: 50px;
    right: calc(50% - 300px);
    transform: rotate(0deg);
  }

  .aditiSection3 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 4 */
  .aditiSection4 .route {
    position: absolute;
    height: 300px;
    z-index: -20;
    left: calc(25%);
    bottom: -10px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .aditiSection4 .text1 {
    position: absolute;
    width: 400px;
    z-index: -1;
    /* font-size: 2.5rem; */
    top: 150px;
    opacity: 0;
    right: -1000px;
    transition: opacity 1.2s, left 1s;
  }
  .aditiSection4 .kochi_map {
    position: absolute;
    z-index: -20;
    height: 600px;
    opacity: 0;
    transform: rotate(20deg);
    left: -1000px;
    bottom: -220px;
    transition: opacity 1s, transform 1.2s, left 1s, bottom 1s;
  }
  .aditiSection4 .kochi_postcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    transform: rotate(30deg);
    opacity: 0;
    left: -1000px;
    top: -1000px;
    transition: opacity 1s, transform 1.2s, left 2s, top 2s;
  }

  /* Transition */
  #aditiSection4TextTrigger {
    top: 5%;
  }
  #aditiSection4MapTrigger {
    top: 30%;
  }
  .aditiSection4 .textTriggered {
    opacity: 1;
    right: calc(10%);
  }
  .aditiSection4 .mapTriggered > .kochi_map {
    opacity: 1;
    bottom: calc(50% - 300px);
    left: calc(10%);
    transform: rotate(0deg);
  }
  .aditiSection4 .mapTriggered > .kochi_postcard {
    transform: rotate(-10deg);
    left: calc(10%);
    top: 250px;
    opacity: 1;
  }

  .aditiSection4 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 5 */
  .aditiSection5 .route {
    position: absolute;
    height: 350px;
    z-index: -20;
    right: calc(25%);
    bottom: -200px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .aditiSection5 .text1 {
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    bottom: 0px;
    transition: opacity 1s, left 1s;
  }

  .aditiSection5 .Sarcophagus {
    position: absolute;
    width: 500px;
    z-index: -1;
    opacity: 0;
    left: calc(50% - 275px);
    top: 200px;
    transition: opacity 1s, top 1s, left 1s;
  }

  /* Transition */
  #aditiSection5TextTrigger {
    top: 5%;
  }
  #aditiSection5MapTrigger {
    top: 10%;
  }
  .aditiSection5 .textTriggered {
    opacity: 1;
  }
  .aditiSection5 .mapTriggered > .Sarcophagus {
    opacity: 1;
    left: calc(50% - 250px);
    top: 0;
  }

  .aditiSection5 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 6 */
  .aditiSection6 .route {
    position: absolute;
    height: 400px;
    z-index: -20;
    right: calc(25%);
    bottom: -350px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }
  .aditiSection6 .text1 {
    position: absolute;
    width: 400px;
    z-index: -1;
    /* font-size: 2.5rem; */
    top: 150px;
    opacity: 0;
    left: -1000px;
    transition: opacity 1.2s, left 1s;
  }
  .aditiSection6 .kolkata_map {
    position: absolute;
    z-index: -20;
    height: 600px;
    opacity: 0;
    transform: rotate(20deg);
    right: -1000px;
    bottom: -220px;
    transition: opacity 1s, transform 1.2s, right 1s, bottom 1s;
  }
  .aditiSection6 .kolkata_postcard {
    position: absolute;
    width: 300px;
    z-index: -1;
    transform: rotate(30deg);
    opacity: 0;
    right: -1000px;
    top: -1000px;
    transition: opacity 1s, transform 1.2s, right 2s, top 2s;
  }

  /* Transition */
  #aditiSection6TextTrigger {
    top: 5%;
  }
  #aditiSection6MapTrigger {
    top: 30%;
  }
  .aditiSection6 .textTriggered {
    opacity: 1;
    left: calc(5%);
  }
  .aditiSection6 .mapTriggered > .kolkata_map {
    opacity: 1;
    bottom: calc(50% - 300px);
    right: calc(5%);
    transform: rotate(0deg);
  }
  .aditiSection6 .mapTriggered > .kolkata_postcard {
    transform: rotate(-10deg);
    right: calc(5%);
    top: 250px;
    opacity: 1;
  }

  .aditiSection6 .mapTriggered > .route {
    opacity: 0.8;
  }

  /* section 7 */
  .aditiSection7 .route {
    position: absolute;
    width: 250px;
    z-index: -20;
    left: calc(50% - 125px);
    top: 15px;
    opacity: 0;
    transition: opacity 0.5s;
    transform: rotate(0);
  }

  .aditiSection7 .text1 {
    position: absolute;
    width: 100%;
    z-index: -1;
    /* font-size: 3rem; */
    top: 50px;
    opacity: 0;
    transition: opacity 1s;
  }

  .aditiSection7 .Girl {
    position: absolute;
    width: 400px;
    z-index: -1;
    transform: rotate(20deg);
    right: 1000px;
    bottom: -220px;
    opacity: 0;
    transition: opacity 1s, transform 1s, right 1s, bottom 1s;
  }

  /* Transition */
  #aditiSection7TextTrigger {
    top: 5%;
  }
  #aditiSection7MapTrigger {
    top: 10%;
  }
  .aditiSection7 .textTriggered {
    opacity: 1;
  }
  .aditiSection7 .mapTriggered > .Girl {
    opacity: 1;
    bottom: 50px;
    right: calc(50% - 200px);
    transform: rotate(0deg);
  }

  .aditiSection7 .mapTriggered > .route {
    opacity: 0.8;
  }
}
