.NotFound {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NotFound p {
  font-size: 3rem;
  color: white;
}
