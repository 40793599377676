.BookReview > table tr {
  /* width: 100%; */
  /* color: white; */
  /* border-radius: 5px; */
  background-color: #2c282c;
  border: 1px solid;
  border-color: #121112;

  height: 3rem;
  padding: 15px;
  box-sizing: border-box;
}

.BookReview > table {
  width: 100%;
}

.BookReview > table td {
  box-sizing: border-box;
  padding: 15px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.book_review.expand > td {
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
}

.BookReview > table th {
  text-align: left;
  background-color: #2c282c;
  border: 1px solid;
  border-color: #121112;
  box-sizing: border-box;
  padding: 15px;
}

.review_star_column {
  min-width: 5rem;
}

.review_review_column {
  width: 20%;
}

.decline.button,
.approve.button {
  all: unset;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid;
  color: white;
  background-color: transparent;
  margin-right: 0.5rem;
}
.decline.button {
  background-color: rgb(255, 68, 68);
}
